/*
 * @Description: 
 * @Author: zhuxiaobing
 * @Date: 2021-07-12 21:50:15
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-08-05 23:39:40
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '../layouts'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: BaseLayout,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/home.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: '/static',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
