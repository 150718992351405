<template>
  <div class="header-box">
    <div>
      <img class="logo" src="/img/logo.png" alt="sypool" />
    </div>
    <div class="inner">
      <a href="#home">
        <div class="menu-item">Home</div>
      </a>
      <a href="#community">
        <div class="menu-item" style="margin-left: 5rem">Community</div>
      </a>
      <a href="https://sypool-protocol.medium.com/">
        <div class="menu-item" style="margin-left: 7rem">Blog</div>
      </a>
      <a href="#docs">
        <div class="menu-item" style="margin-left: 7rem">Docs</div>
      </a>
      <a href="https://app.sypool.io" target="_blank" rel="noopener noreferrer">
        <div class="menu-item2" style="margin-left: 10rem">Get Started</div>
      </a>
    </div>
    <div class="inner-mb">
      <div class="inner-btn" @click="toggleDrop">
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
      <div class="drop" v-show="dropVisible">
        <a href="#home">
          <div class="menu-item3">Home</div>
        </a>
        <a href="#community">
          <div class="menu-item3">Community</div>
        </a>
        <a href="https://sypool-protocol.medium.com/">
          <div class="menu-item3">Blog</div>
        </a>
        <a href="#docs">
          <div class="menu-item3">Docs</div>
        </a>
        <a
          href="https://app.sypool.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="menu-item4">Get Started</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      dropVisible: false,
    };
  },
  methods: {
    toggleDrop() {
      this.dropVisible = !this.dropVisible;
    },
  },
};
</script>

<style lang="less" scoped>
.header-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 101;
  padding: 5rem 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none;
    color: white;
    &:visited {
      color: white;
    }
  }
  .logo {
    height: 3.8rem;
  }
  .inner {
    display: flex;
  }
  .menu-item {
    height: 3.8rem;
    line-height: 3.8rem;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }
  .menu-item2 {
    height: 3.8rem;
    width: 13rem;
    border-radius: 9999px;
    background: #fff;
    text-align: center;
    line-height: 3.8rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: rgb(59, 58, 58);
  }
}

.inner-mb {
  position: relative;
  .inner-btn {
    width: 1.5rem;
    cursor: pointer;
    .item {
      height: 0.3rem;
      margin-bottom: 0.3rem;
      background-color: #fff;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .drop {
    position: absolute;
    bottom: -1rem;
    right: 0;
    transform: translateY(100%);
    padding: 1rem;
    background-color: #000;
    border-radius: 1rem;
  }
  .menu-item3 {
    margin-bottom: 1rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }
  .menu-item4 {
    height: 2rem;
    width: 10rem;
    border-radius: 9999px;
    background: #fff;
    text-align: center;
    line-height: 2rem;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(59, 58, 58);
  }
}

@media screen and (min-width: 768px) {
  .inner-mb {
    display: none;
  }
}

@media screen and(max-width: 768px) {
  .header-box {
    padding: 2rem 2rem;
    .logo {
      height: 2rem;
    }
    .inner {
      display: none;
    }
  }
}
</style>