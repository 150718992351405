import Vue from 'vue'
import {
	Connection,
	SystemProgram,
	Transaction,
	clusterApiUrl,
	PublicKey,
	Keypair
} from '@solana/web3.js'
import Wallet from "@project-serum/sol-wallet-adapter"
import { TOKEN_PROGRAM_ID, Token, AccountLayout, createTokenAccount, createAssociatedTokenAccount } from '@solana/spl-token'

let wallet = ''
let connection = ''

const walletConnection = async (url,page) => {
	let rpcUrl = "http://127.0.0.1:8899"
	rpcUrl = "https://api.devnet.solana.com"
	connection = new Connection(rpcUrl)
	if(wallet._publicKey){
		'_publicKey'
	}
	if (!wallet._publicKey){
			wallet = new Wallet(url, rpcUrl);
	}
	if (wallet.connected == false){
			await wallet.connect()
	}
	
	return [wallet,connection]
}


const createMintTokenAccount = async function(connection,userWallet,mint) {
	// console.log(userWallet,'useruser',mint.toBase58())
	// let newMint = new Token(connection,mint,TOKEN_PROGRAM_ID,userWallet)
	// console.log(newMint,'new mint')
	// let mintAcc = await newMint.createAccount(userWallet.publicKey)
	// return mintAcc
	const balanceNeeded = await this.$store.state.walletConnectInfo.connection.getMinimumBalanceForRentExemption(AccountLayout.span);
	const newAccount = Keypair.generate();
const transaction = new Transaction();
	transaction.add(
  SystemProgram.createAccount({
	fromPubkey: this.$store.state.walletConnectInfo.publicKey,
	newAccountPubkey: newAccount.publicKey,
	lamports: balanceNeeded,
	space: AccountLayout.span,
	programId: TOKEN_PROGRAM_ID,
  })
)
 transaction.add(
  Token.createInitAccountInstruction(
	TOKEN_PROGRAM_ID,
	mint,
	newAccount.publicKey,
	this.$store.state.walletConnectInfo.wallet.publicKey,
  )
)
transaction.feePayer = this.$store.state.walletConnectInfo.publicKey
console.log(3,transaction)
let { blockhash } = await this.$store.state.walletConnectInfo.connection.getRecentBlockhash();
console.log(4)
transaction.recentBlockhash = blockhash;
console.log(this.$store.state.walletConnectInfo.wallet,newAccount,'aaaa')
let signed = await this.$store.state.walletConnectInfo.wallet.signTransaction(transaction)
console.log(5)
transaction.partialSign(newAccount)
let result = await this.$store.state.walletConnectInfo.connection.sendRawTransaction(signed.serialize());
console.log(result)
return result

}

export default{
	walletConnection,
	createMintTokenAccount
  }