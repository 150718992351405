/*
 * @Description: 
 * @Author: zhuxiaobing
 * @Date: 2021-07-12 21:50:15
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-09-22 23:36:08
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import wallet from '../plugins/wallet'
import Axios from './plugin/axios'
// Axios.defaults.baseURL='http://127.0.0.1:8091/'

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true

Vue.prototype.$axios = Axios

Vue.use(VueClipboard)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$walletConnection = wallet.walletConnection;
Vue.prototype.$createMintTokenAccount = wallet.createMintTokenAccount;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
